@import '../../styles/media-queries';

.footer-menu {
  $menu: &;

  @include for-screen-up(1366) {
    padding-top: 3rem;
  }

  &__heading {
    color: var(--text-color-primary-white);
    font-size: 2.1rem;
    margin: 0 0 2.1rem;
    line-height: 1.3;
    font-weight: normal;

    @include for-screen-up(640) {
      margin-bottom: 2.7rem;
      font-size: 1.6rem;
    }

    @include for-screen-up(1024) {
      margin-bottom: 2.8rem;
      font-size: 1.7rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 3.4rem;
      font-size: 2.1rem;
    }

    @include for-screen-up(1680) {
      margin-bottom: 3.4rem;
      font-size: 2.1rem;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 0 0 2rem;
    padding: 0;
    color: var(--text-color-primary-white);
    font-family: Gilroy, sans-serif;
    font-size: 1.5rem;
    line-height: 1.1;

    &:last-child {
      margin-bottom: 0;
    }

    @include for-screen-up(640) {
      font-size: 1.3rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 1.8rem;
      font-size: 1.4rem;
    }

    @include for-screen-up(1024) {
      margin: 0 0 2rem;
      font-size: 1.4rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 2.4rem;
      font-size: 1.6rem;
    }

    @include for-screen-up(1680) {
      margin: 0 0 2.4rem;
      font-size: 1.6rem;
    }
  }

  &__link {
    color: inherit;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    position: relative;
  }

  &__icon {
    display: flex;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 2.1rem;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    & ~ #{$menu}__text {
      padding-left: 3.5rem;
    }
  }
}
