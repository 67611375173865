@import '../../styles/media-queries';

.footer {
  $footer: &;
  overflow: hidden;
  position: relative;

  @include for-screen-up(640) {
    padding: 0 1rem 1rem;
  }

  @include for-screen-up(1366) {
    padding: 0 2rem 2rem;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    margin: auto;
    box-sizing: border-box;
    flex-direction: column;
    flex-wrap: wrap;

    width: 100%;
    max-width: 188rem;
    position: relative;
    z-index: 1;

    padding: 5.2rem 2.6rem 4.8rem;

    &:before {
      content: '';
      border-radius: 4rem 4rem 0 0;

      background: var(--bg-color-primary-blue);
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      opacity: 0;
      transform: scale(0.8) translateY(50%);
      transition: transform 0.5s ease, opacity 0.5s ease;
      will-change: transform, opacity;

      @include for-screen-up(640) {
        border-radius: 4.7rem;
      }

      @include for-screen-up(1024) {
        border-radius: 5rem;
      }

      @include for-screen-up(1366) {
        border-radius: 6rem;
      }

      #{$footer}_animation-start & {
        transform: scale(1) translateY(0%);
        opacity: 1;
      }
    }

    @include for-screen-up(640) {
      flex-direction: row;
      padding: 8.2rem 5rem 4.8rem;
    }

    @include for-screen-up(960) {
      padding: 12.7rem 9.5rem 4.9rem 12rem;
    }

    @include for-screen-up(1024) {
      padding: 15.3rem 9.9rem 5.2rem 12.8rem;
    }

    @include for-screen-up(1366) {
      padding: 15.3rem 7.5rem 7.4rem 16.5rem;
    }

    @include for-screen-up(1680) {
      padding: 15.3rem 23.6rem 7.4rem 24.7rem;
    }

    @include for-screen-up(1920) {
      padding: 15.3rem 35.6rem 7.4rem 30.9rem;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
  }

  &__column {
    margin-bottom: 5.5rem;

    @include for-screen-up(960) {
      margin-bottom: 0;
    }

    &:first-child {
      order: 4;
      margin-bottom: 0;

      @include for-screen-up(640) {
        width: 100%;
      }

      @include for-screen-up(960) {
        order: 0;
        width: auto;
        margin-right: 5.8rem;
      }

      @include for-screen-up(1024) {
        margin-right: 4.2rem;
      }
    }

    &:nth-child(3) {
      @include for-screen-up(640) {
        min-width: 14rem;
      }
      @include for-screen-up(960) {
        min-width: 0;
      }
    }
  }

  &__arda-digital {
    padding-top: 2.4rem;
    display: flex;
    justify-content: center;

    @include for-screen-up(1024) {
      padding-top: 4rem;
    }
  }

  &__arda-digital-img {
    display: block;
  }
}
